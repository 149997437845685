import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from 'twin.macro';
import styled, { css } from 'styled-components/macro'; //eslint-disable-line
import Header, { NavLink, NavLinks, PrimaryLink } from 'components/headers/light.js';
import Footer from 'components/footers/EskwelaFooter.js';
import ContactUsForm from 'components/forms/EskwelaEmailForm';
import EskwelaLogo from 'images/eskwela-app-logo.svg';
import GetStarted from 'components/cta/PhoneContacts.js';

const LogoLink = styled(NavLink)`
	${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

	img {
		${tw`w-72 mr-3`}
	}
`;

export const EskwelaLinks = [
	<NavLinks key={1}>
		<NavLink href="https://iligan.eskwela.app" tw="lg:ml-12!">
			Sign In
		</NavLink>
		<PrimaryLink css={tw`rounded-full`} href="/contact-us">
			Contact Us to Sign Up
		</PrimaryLink>
	</NavLinks>
];

export const Logo = (
	<LogoLink href="/">
		<img src={EskwelaLogo} alt="Eskwela App" />
	</LogoLink>
);

export default () => {
	return (
		<AnimationRevealPage disabled>
			<Header roundedHeaderButton logoLink={Logo} links={EskwelaLinks} />
			<ContactUsForm
				subheading={null}
				heading={
					<>
						Get in touch with us by <span tw="text-primary-500">email</span>
						<wbr />.
					</>
				}
				description="Please provide all the details we need so we could reach out to your Division Office and start the sign up process ASAP."
				textOnLeft
			/>
			<GetStarted
				text="Are you ready to take your school to a whole new level?"
				primaryLinkText={'Contact Us to Sign Up'}
				primaryLinkUrl="/contact-us"
				pushDownFooter={true}
			/>
			<Footer />
		</AnimationRevealPage>
	);
};
