import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyAoBa120iYfY7l7LuN7DxWYYNsh2RaJdzM',
	authDomain: 'eskwela-app-home.firebaseapp.com',
	databaseURL: 'https://eskwela-app-home.firebaseio.com',
	projectId: 'eskwela-app-home',
	storageBucket: 'eskwela-app-home.appspot.com',
	messagingSenderId: '871509345379',
	appId: '1:871509345379:web:c23769aceed2d0c3f62456',
	measurementId: 'G-FF3VJSPC1D'
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
export const firestore = firebase.firestore();
