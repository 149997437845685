import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/EskwelaHero";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import GetStarted from "components/cta/GetStartedEskwela";
import Footer from "components/footers/EskwelaFooter.js";
import teachers from "images/teachers.svg";
import students from "images/students.svg";
import schoolAdmin from "images/school-admin.svg";
import divisionOfficers from "images/divisionOfficers.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const otherFeaturesCard = [
  {
    imageSrc: ShieldIconImage,
    title: "Secure",
    description:
      "Login with your DepEd or personal Google account. Every user-sensitive access are verified by Google OAuth team to keep your privacy.",
  },
  {
    imageSrc: SimpleIconImage,
    title: "User-friendly",
    description:
      "Modern and slick user interface. Choose from seven different color themes (more to come) and turn the light or dark mode on whenever you please.",
  },
  {
    imageSrc: ReliableIconImage,
    title: "Reliable",
    description:
      "Guaranteed 99.9% uptime. Our application is deployed in Firebase, an immensely popular BaaS by Google.",
  },
  {
    imageSrc: FastIconImage,
    title: "Blazing Fast",
    description:
      "Real-time data is guaranteed in our service. Search through your large database and get results in no time.",
  },
  {
    imageSrc: CustomizeIconImage,
    title: "Mobile-friendly",
    description:
      "Optimized not only for desktop users but also for tablets and phones.",
  },
  {
    imageSrc: SupportIconImage,
    title: "Continuously Developed",
    description:
      "We are working to expand the feature of the application to serve your school better.",
  },
];

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>
      <Hero roundedHeaderButton={true} />
      <MainFeature
        heading={
          <>
            Manage your subject and advisory classes{" "}
            <span tw="text-primary-500">anywhere, anytime.</span>
          </>
        }
        description="Conduct video meetings with your class, create class works with Google Classroom, input grades, monitor class attendance and more. Save time, get organized!"
        subheading={<Subheading>For Teachers</Subheading>}
        imageSrc={teachers}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonUrl="/contact-us"
        primaryButtonText="Want to learn more? Contact Us"
      />
      <MainFeature
        heading={
          <>
            Get all your records and do all classroom works{" "}
            <span tw="text-primary-500">online.</span>
          </>
        }
        description="Get notifications of new Google Classroom work, join video meeting of your class securely, get your past and present school records just by looking at the screen."
        subheading={<Subheading>For Students</Subheading>}
        imageSrc={students}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonUrl="/contact-us"
        primaryButtonText="Want to learn more? Contact Us"
        textOnLeft={false}
      />
      <MainFeature
        heading={
          <>
            Access 100% of school data{" "}
            <span tw="text-primary-500">accurately and securely.</span>
          </>
        }
        description="Manage comfortably school sections, students' enrollment, teachers' load, personal profile of every individual and application users with zero headache and paperwork."
        subheading={<Subheading>For School Admins and Heads</Subheading>}
        imageSrc={schoolAdmin}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonUrl="/contact-us"
        primaryButtonText="Want to learn more? Contact Us"
      />
      <MainFeature
        heading={
          <>
            Receive real-time statistics and reports from all schools{" "}
            <span tw="text-primary-500">remotely.</span>
          </>
        }
        description="No need to wait for reports to be taken to the office. Access all the live data from all schools, search through the student and employee registry and manage virtually anyone from the comfort of your office."
        subheading={<Subheading>For Division Officers</Subheading>}
        imageSrc={divisionOfficers}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonUrl="/contact-us"
        primaryButtonText="Want to learn more? Contact Us"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Other Features</Subheading>}
        heading={
          <>
            Your school experience will <HighlightedText>never</HighlightedText>{" "}
            be the same again.
          </>
        }
        description="This web application is packed with modern, customizable and performant features for everyone."
        cards={otherFeaturesCard}
      />
      <GetStarted
        text="Are you ready to take your school to a whole new level?"
        primaryLinkText={"Contact Us to Sign Up"}
        primaryLinkUrl="/contact-us"
        pushDownFooter={true}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
