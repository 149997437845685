import React, { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import EmailIllustrationSrc from 'images/email-illustration.svg';
import firebase, { firestore } from 'constants/firebase';
import { AlertError, AlertSuccess } from 'components/misc/Alert';
import { ReCaptcha } from 'react-recaptcha-v3';
import { useRef } from 'react';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
	tw`md:w-7/12 mt-16 md:mt-0`,
	props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div((props) => [
	`background-image: url("${props.imageSrc}");`,
	tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
	SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-3xl lg:text-4xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: 'textarea' })`
	${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default ({
	subheading = 'Contact Us',
	heading = (
		<>
			Feel free to <span tw="text-primary-500">get in touch</span>
			<wbr /> with us.
		</>
	),
	description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	submitButtonText = 'Send',
	formAction = '#',
	formMethod = 'get',
	textOnLeft = true
}) => {
	// The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [fullname, setFullname] = useState('');
	const [school, setSchool] = useState('');
	const [division, setDivision] = useState('');
	const [message, setMessage] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [sendSuccessMessage, setSendSuccessMessage] = useState(null);
	const [sendFailMessage, setSendFailMessage] = useState(null);
	const contactEmailreCAPTCHA = useRef();

	const resetForm = () => {
		setEmail('');
		setFullname('');
		setSchool('');
		setDivision('');
		setMessage('');
		setPhoneNumber('');
	};

	const handleEmailSubmit = async () => {
		try {
			setSubmitting(true);
			const contactEmailRef = firestore.collection('contact_emails').doc();
			await contactEmailRef.set({
				email,
				fullname,
				school,
				division,
				message,
				phoneNumber,
				createdAt: firebase.firestore.FieldValue.serverTimestamp()
			});
			setSendSuccessMessage("Email sent! We'll contact you as soon as we can. Thank you.");
			resetForm();
		} catch (error) {
			setSendFailMessage('We have trouble sending your email. Please try again.');
		}
		setSubmitting(false);
	};

	const verifyCallback = (recaptchaToken) => {
		// Here you will get the final recaptchaToken!!!
		// console.log(recaptchaToken, '<= your recaptcha token');
		if (!recaptchaToken) {
			updateToken();
		}
	};

	const updateToken = () => {
		// you will get a new token in verifyCallback
		contactEmailreCAPTCHA.current.execute();
	};

	return (
		<Container>
			<TwoColumn>
				<ImageColumn>
					<Image imageSrc={EmailIllustrationSrc} />
				</ImageColumn>
				<TextColumn textOnLeft={textOnLeft}>
					<TextContent>
						{subheading && <Subheading>{subheading}</Subheading>}
						<Heading>{heading}</Heading>
						{description && <Description>{description}</Description>}
						{sendFailMessage && <AlertError>{sendFailMessage}</AlertError>}
						{sendSuccessMessage && <AlertSuccess>{sendSuccessMessage}</AlertSuccess>}
						<Form onSubmit={handleEmailSubmit}>
							<Input
								type="email"
								name="email"
								placeholder="Your Email Address"
								value={email}
								required
								onChange={(e) => setEmail(e.target.value)}
							/>
							<Input
								type="phoneNumber"
								name="phoneNumber"
								placeholder="Your Phone Number"
								value={phoneNumber}
								required
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
							<Input
								type="text"
								name="name"
								placeholder="Full Name"
								value={fullname}
								required
								onChange={(e) => setFullname(e.target.value)}
							/>
							<Input
								type="text"
								name="school"
								placeholder="School"
								value={school}
								required
								onChange={(e) => setSchool(e.target.value)}
							/>
							<Input
								type="text"
								name="disivion"
								placeholder="Division"
								value={division}
								required
								onChange={(e) => setDivision(e.target.value)}
							/>
							<Textarea
								name="message"
								placeholder="Your Message Here"
								value={message}
								required
								onChange={(e) => setMessage(e.target.value)}
							/>
							<SubmitButton
								type="submit"
								disabled={submitting}
								onClick={(e) => {
									e.preventDefault();
									handleEmailSubmit();
								}}
							>
								{submitButtonText}
							</SubmitButton>
							<ReCaptcha
								ref={contactEmailreCAPTCHA}
								sitekey={SITE_KEY}
								action="submit_email_inquiry"
								verifyCallback={verifyCallback}
							/>
						</Form>
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	);
};
