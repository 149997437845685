import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-9.svg';
import { ContentWithPaddingXl, Container } from 'components/misc/Layouts';

const PrimaryBackgroundContainer = tw.div`py-16 lg:py-20 bg-purple-200 rounded-lg relative`;
const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer1 = tw.div`lg:w-1/3`;
const ColumnContainer2 = tw.div`lg:w-2/3`;
const TextContainer = tw(ColumnContainer1)`text-2xl sm:text-4xl font-bold`;
const Subheading = tw.h6`text-primary-500 opacity-75`;
const Heading = tw.h5`text-primary-500`;

const LinksContainer = tw(ColumnContainer2)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

// const Link = tw.a`w-full sm:w-auto text-sm text-center sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
// const PrimaryLink = tw(Link)`shadow text-gray-100 hocus:text-gray-300 bg-primary-500 hocus:bg-primary-700`;

// const SecondaryLink = tw(Link)`text-primary-500 hover:text-primary-600 bg-gray-100 hover:bg-gray-200`;

const Card = styled.div`
	${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
	.imageContainer {
		${tw`border text-center rounded-full p-5 flex-shrink-0`}
		img {
			${tw`w-6 h-6`}
		}
	}

	.textContainer {
		${tw`sm:ml-4 mt-4 sm:mt-2`}
	}

	.title {
		${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
	}

	.description {
		${tw`mt-1 sm:mt-1 font-medium text-secondary-100 leading-loose`}
	}
`;

const Email = tw.span`mt-3 block`;
const Phone = tw.span`mt-0 block`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
	SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-500 opacity-5`;
const DecoratorBlob2 = tw(
	SvgDecoratorBlob1
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-500 opacity-5`;
export default ({
	subheading = 'Interested in Treact ?',
	heading = 'Join the closed beta now.',
	primaryLinkText = 'Get Started',
	primaryLinkUrl = 'http://timerse.com',
	secondaryLinkText = 'Contact Us',
	secondaryLinkUrl = 'http://google.com',
	pushDownFooter = true
}) => {
	return (
		<Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
			<ContentWithPaddingXl>
				<PrimaryBackgroundContainer>
					<Row>
						<TextContainer>
							{subheading && <Subheading>Need to talk about it?</Subheading>}
							<Heading>Call or email us now.</Heading>
						</TextContainer>
						<LinksContainer>
							<Card>
								<span className="textContainer">
									<span className="title">Francis Mendiola</span>
									<p className="description">Logistics and Design</p>
									<Email>
										<a href="mailto:francism1011@gmail.com">francism1011@gmail.com</a>{' '}
									</Email>
									<Phone>
										<a href="tel:+63 955 112 1281">+63 955 112 1281</a>{' '}
									</Phone>
								</span>
							</Card>
							<Card>
								<span className="textContainer">
									<span className="title">Arjay Labadan</span>
									<p className="description">Project Developer</p>
									<Email>
										<a href="mailto:arjayjadelabadan@gmail.com">arjayjadelabadan@gmail.com</a>
									</Email>
									<Phone>
										<a href="tel:+63 917 897 2685">+63 917 897 2685</a>
									</Phone>
								</span>
							</Card>
						</LinksContainer>
					</Row>
					<DecoratorBlobContainer>
						<DecoratorBlob1 />
						<DecoratorBlob2 />
					</DecoratorBlobContainer>
				</PrimaryBackgroundContainer>
			</ContentWithPaddingXl>
		</Container>
	);
};
