import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

import Header, { NavLink, NavLinks, PrimaryLink } from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
// import DesignIllustration from '../../images/design-illustration-2.svg';
import DesignIllustration from "../../images/stats-illustration.svg";
// import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import EskwelaLogo from "../../images/eskwela-app-logo.svg";
import Matatag from "../../images/matatag-deped.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24 lg:gap-16`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700 text-left leading-loose`;
// const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-72 mr-3`}
  }
`;

export const Logo = (
  <LogoLink href="/">
    <img src={EskwelaLogo} alt="Eskwela App" />
  </LogoLink>
);

const features = [
  "Fully supports Senior High School curriculum",
  "Blazingly fast and real-time data.",
  "Access in any device, anywhere.",
  "Sign in with Google securely.",
];

export const EskwelaLinks = [
  <NavLinks key={1}>
    <NavLink href="https://iligan.eskwela.app" tw="lg:ml-12!">
      Sign In
    </NavLink>
    <PrimaryLink css={tw`rounded-full`} href="/contact-us">
      Contact Us to Sign Up
    </PrimaryLink>
  </NavLinks>,
];

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header
        roundedHeaderButton={roundedHeaderButton}
        logoLink={Logo}
        links={EskwelaLinks}
      />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Take your school to a whole new level.
              <span tw="text-primary-500"> Now!</span>
            </Heading>
            <Paragraph>
              We empower DepEd's K to 12 public schools with modern, cloud-based
              and secure School Management System, with Distance Learning
              features - Google Classroom integration and live video classes. It
              fully supports the <strong>MATATAG Curriculum</strong>.
            </Paragraph>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={Matatag}
                alt="MATATAG Banner"
              />
            </IllustrationContainer>
            {/* <Actions>
							<input type="text" placeholder="Your E-mail Address" />
							<button>Get Started</button>
						</Actions> */}
            <FeatureList>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIcon />
                  <FeatureText>{feature}</FeatureText>
                </Feature>
              ))}
            </FeatureList>
            {/* <CustomersLogoStrip>
							<p>Our TRUSTED Customers</p>
							<img src={CustomersLogoStripImage} alt="Our Customers" />
						</CustomersLogoStrip> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
